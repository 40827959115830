
/**
 * 获取url中的参数
 * @param { String } src 地址参数 非必须 
 */
export const queryStringAll = (src) => {
    src = src || location.search || location.hash;
    let res = {};
    src.replace(/[?&]([^?&=]+)=([^?&=]*)/g, (rs,$1,$2) => {
        res[$1] = $2;
    })
    return res;
}