import { request, instance } from 'hxhyaxios'
import { getStore } from "@/util/store"

instance.interceptors.request.use(
  (config) => {
    const token = getStore({ name: "access_token" });
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    //对请求错误做点什么
    return Promise.reject(error);
  }
)

export default request;
