import request from "./axios";
import { apiList as api } from "./apiList";

// 获取微信配置信息
export function wxEmpower(appid, code, scope) {
  return request({
    url: api.wxEmpower + `${appid}/${code}/${scope}`,
    method: "get"
  });
}

// 支付单下单
export function placePayOrder(data) {
  return request({
    url: api.placePayOrder,
    method: "post",
    showWarning: false,
    showError: false,
    data
  });
}

// 查询订单信息
export function getOrderInfo(code) {
  return request({
    url: api.getOrderInfo,
    method: "get",
    params: { code }
  });
}

/**
 * 获取token
 * @param {*} data
 * @returns
 */
export function getToken(datas, baseURL, authCode) {
  const data = new FormData();
  data.append('mobile', datas.mobile)
  data.append('code', datas.code)
  return request({
    url: api.getToken + '?grant_type=mobile',
    method: 'post',
    headers: {
      Authorization: `Basic ${authCode}`
    },
    showError: false,
    baseURL: baseURL,
    data
  })
}