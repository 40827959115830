<template>
  <div class="code">
    <img :src="require('./image/code.png')" />
    <div v-if="!showFail" class="mask loading">登录中...</div>
    <div v-else class="mask">扫码失败</div>
  </div>
</template>

<script setup>

import { ref } from 'vue'
import { useRoute } from "vue-router"
import { getToken } from '@/api/index'

const route = useRoute()
const showFail = ref(false)

const whiteSpace = ['zhixuehuijiao.cn', 'huixuecloud.cn', ]

let fromOrigin = ''

/**
 * 获取token
 * @param {*} code 微信授权成功后返回的code
 */
const getTokenMsg = (code) => {

  const envname = route.query.state.split('~')[0]
  const baseURL = envname.includes('dev') ? '/devbapi' : envname === '' ? '/bapi' : `/${envname}bapi`
  const data = {
    mobile: `WX@${code}`,
    code: code,
  }

  getToken(data, baseURL, 'Y29tbW9uOmNvbW1vbg==').then((res) => {
    const msg = {
      notBind: false,
      userInfo: {},
      code: code
    }
    
    if (res?.data?.code) {
      if (res.data.msg === 'wx_error_003') {
        msg.notBind = true
        sendmsg(msg)
      } else {  // 失败
        showFail.value = true
      }
    } else {
      msg.userInfo = res.data
      sendmsg(msg)
    }
  })
  .catch((err) => {
    showFail.value = true
  })
}

/**
 * 通知嵌入主体网站
 * @param { Object } msg 发送消息对象
 */
const sendmsg = (msg) => {
  if (route.query.code && route.query.state) {
    const [envname, business, protocol, origin] = route.query.state.split('~')
    if (origin) {
      fromOrigin = decodeURIComponent(atob(origin))
      const hosts = fromOrigin.split('.').reverse()
      const host = `${hosts[1]}.${hosts[0]}`
      if (whiteSpace.some(safe => host.includes(safe))) {
        window.top.postMessage(msg, fromOrigin)
      }
    }
  }
}

// 获取地址参数 route.query.code
const code = route.query.code
getTokenMsg(code)

</script>

<style lang="scss" scoped>
.code {
  position: relative;
  width: 200px;
  height: 200px;
}
img {
  width: 100%;
  height: 100%;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 75px 0;
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  opacity: 0.8;
}
</style>
    