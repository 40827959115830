<template>
  <div class="code">
    <img :src="require('./image/code.png')" />
    <div v-if="!showFail" class="mask loading">登录中...</div>
    <div v-else class="mask">扫码失败</div>
  </div>
</template>
<script setup>
import { getToken } from '@/api/index'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
const route = useRoute()
// 获取地址参数 route.query.code
let code = route.query.code
const baseURL = ref('')
const showFail = ref(false)

/**
 * 获取token
 * @param {*} code 微信授权成功后返回的code
 */
const getTokenMsg = async (code) => {
  if (route.query.state) {
    const envname = route.query.state.split('~')[0]
    baseURL.value = envname.includes('dev') ? '/devbapi' : envname === '' ? '/bapi' : `/${envname}bapi`
  }
  const data = {
    mobile: `WX@${code}`,
    code: code,
  }
  await getToken(data, baseURL.value, 'bGFyZ2Vfc2NyZWVuOmxhcmdlX3NjcmVlbg==')
    .then((res) => {
      // console.log(res)
      let obj = {}
      // 失败
      if (res?.data?.code) {
        showFail.value = true
        obj = getBridge('False', '', getFailMsg(res.data.msg))
      } else {
        obj = getBridge('True', JSON.stringify(res.data), '登录成功')
      }
      sendBridge(obj)
    })
    .catch((err) => {
      // console.log(err, '---err')
      showFail.value = true
      sendBridge(getBridge('False', '', '登录失败'))
    })
}
/**
 * 获取传递对象
 */
const getBridge = (success, token, msg) => {
  return  {
    success: success,
    token: token,
    msg: msg,
  }
}
/**
 * 获取错误类型
 * @param {*} msg 错误类型
 */
const getFailMsg = (msg) => {
  return new Map([
    ['wx_error_001', '获取微信token失败'],
    ['wx_error_002', '获取微信用户信息失败'],
    ['wx_error_003', '微信未绑定'],
  ]).get(msg)
}
// 与 C# 交互
/** 方法名 weixinLogin
    success string
    token string
    msg string
    */
const sendBridge = (data) => {
  // eslint-disable-next-line no-undef
  if (typeof jsCsharp !== 'undefined') {
    // eslint-disable-next-line no-undef
    jsCsharp.weixinLogin(data.success, data.token, data.msg)
  } else {
    alert('未成功绑定jsCsharp对象')
  }
}

onMounted(() => {
  if (typeof CefSharp !== 'undefined') {
    // eslint-disable-next-line no-undef
    CefSharp.BindObjectAsync('jsCsharp').then(() => {
      getTokenMsg(code)
    })
  } else {
    getTokenMsg(code)
  }
})
</script>
<style lang="scss" scoped>
.code {
  position: relative;
  width: 200px;
  height: 200px;
}
img {
  width: 100%;
  height: 100%;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 75px 0;
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  background-color: #000000;
  opacity: 0.8;
}
</style>
