<template>
  <div></div>
</template>

<script setup>
import { useRoute } from "vue-router";

const route = useRoute()
const projname = 'wx-qrcodescan'

if (route.query.code && route.query.state) {
  const [envname, business, protocol] = route.query.state.split('~')
  const curname = (envname === '') ? 'web' : 'main'
  const host = (envname.includes('dev') || envname === '' ) ? 'zhixuehuijiao.cn' : 'huixuecloud.cn'
  window.top.location.href = `${protocol}://${envname}${curname}.${host}/accountInfo?code=${route.query.code}&state=${route.query.state}`
}

</script>

<style lang="scss" scoped>
</style>
    