<template>
  <div id="code"></div>
</template>

<script setup>

import { onMounted } from 'vue'
import { queryStringAll } from '@/util/normal'

const projname = 'wx-qrcodescan'
const info = {
  id: 'wx1fde694f4ac09ed1',
  backDomain: 'prewx-qrcodescan.huixuecloud.cn'
}

const envname = location.host.split(projname)[0]
const protocol = location.protocol

const business = {
  '101': {
    path: '/loginscreen',
    state: ''
  },
  '102': {
    path: '/logintpad',
    state: ''
  },
  '103': {
    path: '/loginpc',
    state: ''
  },
  '201': {
    path: '/bindtpad',
    state: ''
  },
  '202': {
    path: '/bindpc',
    state: ''
  }
}


onMounted(() => {
  const pageParam = queryStringAll()
  if (pageParam.business && Object.keys(business).includes(pageParam.business)) {
    const config = {
      self_redirect: true,
      id: "code",
      appid: info.id,
      scope: "snsapi_login",
      state:`${envname}~${pageParam.business}~${protocol.split(':')[0]}~${pageParam.origin}`,
      response_type: 'code',
      redirect_uri: '',
      href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLndycF9jb2RlIHt3aWR0aDoyMDBweDt9Ci5pbXBvd2VyQm94IC5xcmNvZGUge3dpZHRoOjIwMHB4O2JvcmRlcjogbm9uZTttYXJnaW4tdG9wOiAwO30KLmltcG93ZXJCb3ggLnN0YXR1c19icm93c2VyIHtkaXNwbGF5OiBub25lO30="
    }
    const curBS = business[pageParam.business]
    config.redirect_uri = encodeURIComponent(`${protocol}//${info.backDomain}${curBS.path}?env=${envname}`)

    /* eslint-disable-next-line no-undef */
    new WxLogin(config)
  } else {
    console.log('缺少必要参数')
  }
})
</script>

<style lang="scss">
html,
body,
#lcode,
iframe {
  width: 200px;
  height: 200px;
  overflow: hidden;
}
</style>
